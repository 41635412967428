import React, { useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import redditIcon from '../assets/reddit-icon.png'

/**
 * @typedef SearchFormProps
 */

/**
 * @param {SearchFormProps} props 
 */
const SearchForm = (props) => {

    const size = useStoreState(state => state.posts.length === 0 ? 'big' : 'default')
    const disabled = useStoreState(state => state.fetching)
    const [ query, setQuery ] = useState('')
    const fetchPosts = useStoreActions(actions => actions.fetchPosts)


    const handleSubmit = e => {
        e.preventDefault()
        fetchPosts(query)
    }

    const formClassName = `size-${size}`
    return (
        <div className="module search-form">
            <div className="search-input">
                <form className={formClassName} onSubmit={handleSubmit}>
                    <div className="input-group flex-nowrap">
                            <span className="input-group-text" id="search-reddit">
                                <img src={redditIcon} />
                            </span>
                            <input disabled={disabled} value={query} onChange={(e) => setQuery(e.target.value)} type="text" className="form-control" placeholder="Search reddit..." aria-label="Reddit Search" aria-describedby="search-reddit" />
                            <button className="btn btn-primary" type="submit" id="search-reddit">
                                <i className="bi bi-search"></i>
                            </button>
                    </div>
                </form>
            </div>
        </div>
    )

}

export default SearchForm