import RedditPosts from '../../common/src/RedditPosts'

class RedditPostService {

    isOffline = false

    constructor() {
        setInterval(() => this.pingServer(), 10 * 1000)
        this.pingServer()
    }

    async fetchPosts(query) {
        if (this.isOffline) {
            return this.fetchFromReddit(query)
        }
        let response = await fetch(`http://localhost:3000/api/v1/search?q=${query}`)
        let data = await response.json()
        return data.data
    }

    /**
     * 
     * @param {string} query 
     */
    async fetchFromReddit(query) {
        let response = await fetch(`https://www.reddit.com/search.json?q=${query}&sort=new`)
        let data = await response.json()
        let parser = new RedditPosts(data)
        return parser.list
    }

    async pingServer() {
        let isOffline = null
        try {
            let response = await fetch(`http://localhost:3000/api/v1/healthcheck`)
            isOffline = response.status !== 200
        } catch (error) {
            isOffline = true
        }
        if (this.isOffline !== isOffline) {
            console.log(isOffline ? 'server is offline' : 'server is online')
        }
        this.isOffline = isOffline
    }

}

RedditPostService.Instance = new RedditPostService()

export default RedditPostService