import React from 'react'
import SearchHeader from '../modules/SearchHeader'
import SearchForm from '../modules/SearchForm'
import RedditPosts from '../modules/RedditPosts'

const SearchPage = (props) => {



    return (
        <div className="container-md">
            <div className="row">
                <div className="col">
                    <SearchHeader />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <SearchForm />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <RedditPosts />
                </div>
            </div>
        </div>
    )

}

export default SearchPage