import React from 'react'

const NotFoundPage = (props) => {

    return (
        <>Not found page</>
    )

}

export default NotFoundPage