import { generateId } from '@toolcase/base'

/**
 * @typedef Post
 * @property {string} id
 * @property {string} title
 * @property {string} selfText
 * @property {string} url
 * @property {string} author
 * @property {string} thumbnailUrl
 */

class RedditPosts {

    /** @private */
    DEFAULT_IMAGE = 'https://i.imgur.com/RJrO9Xil.jpg'

    /**
     * @readonly
     * @type {Array<Post>}
     */
    list = []

    constructor(data) {
        this.parse(data)
    }

    parse(data = {}) {
        this.list = []
        data.data.children.forEach(item => {
            let thumbnailUrl = null
            const {
                author,
                title,
                url,
                selftext,
                preview = null
            } = item.data

            if (preview === null) {
                thumbnailUrl = this.DEFAULT_IMAGE
            } else {
                const { images = [] } = preview
                const [ imgEntry = null ] = images
                if (imgEntry === null) {
                    thumbnailUrl = this.DEFAULT_IMAGE
                } else {
                    thumbnailUrl = imgEntry.source.url.replace(/&amp;/g, '&')
                }
            }
            const id = generateId(16)
            this.list.push({
                id, title, selfText: selftext, url, author, thumbnailUrl
            })
        })
    }

}

export default RedditPosts