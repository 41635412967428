import { useStoreState } from 'easy-peasy'
import React from 'react'

const Spinner = () => (
    <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden"></span>
    </div>
)

const PostResult = ({ post }) => {

    const { title, selfText, url, author, thumbnailUrl } = post

    return (
        <div className="post-result">
            <div className="post-thumbnail">
                <img src={thumbnailUrl} />
                <div className="post-author">{author}</div>
            </div>
            <div className="post-data">
                <a href={url}>
                    <div className="post-title">{title}</div>
                </a>
                <div className="post-content">{selfText}</div>
            </div>
        </div>
    )

}

/**
 * @typedef RedditPostsProps
 */

/**
 * @param {RedditPostsProps} props 
 */
const RedditPosts = (props) => {

    const isLoading = useStoreState(state => state.fetching)
    const posts = useStoreState(state => state.posts)

    let rows = []
    let temp = []
    for (let post of posts) {
        temp.push(post)
        if (temp.length >= 3) {
            rows.push([ ...temp ])
            temp = []
        }
    }


    return (
        <div className="module reddit-posts">
            {isLoading ? <Spinner /> : (<></>)}
            {rows.map((rowItems, index) => (
                <div key={index} className="row">
                    {rowItems.map(post => (
                        <div key={post.id} className="col col-md-4"><PostResult post={post} /></div>
                    ))}
                </div>
            ))}
        </div>
    )

}

export default RedditPosts