import React from 'react'
import { useStoreState } from 'easy-peasy'

/**
 * @typedef SearchHeaderProps
 */

/**
 * @param {SearchHeaderProps} props 
 */
const SearchHeader = (props) => {

    const isVisible = useStoreState(state => state.posts.length === 0)

    const blockClassName = `search-header-block${isVisible ? '' : ' hidden'}`
    return (
        <div className="module search-header">
            <div className={blockClassName}>
                <div className="page-title">Search <span>Reddit</span></div>
                <div className="page-slogan">
                    <span>Reddit</span>'s search might be <b>terrible</b>, We are trying to make it <b>better</b>.
                </div>
            </div>
        </div>
    )

}

export default SearchHeader