import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'

import store from './store'
import SearchPage from './pages/SearchPage'
import NotFoundPage from './pages/NotFoundPage'

const Router = () => {
    
    return (
        <StoreProvider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<SearchPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </StoreProvider>
    )
}

export default Router