import { action, createStore, thunk } from 'easy-peasy'
import RedditPostService from './services/RedditPostService'

const store = createStore({

    posts: [],

    fetching: false,

    setPosts: action((state, payload = []) => {
        if (Array.isArray(payload)) {
            state.posts = payload
        } else {
            state.posts = []
        }
    }),

    setFetching: action((state, payload = false) => {
        state.fetching = payload
    }),

    fetchPosts: thunk(async (actions, payload) => {
        
        if (typeof payload !== 'string') {
            actions.setPosts()
            return
        }

        actions.setFetching(true)

        try {
            const data = await RedditPostService.Instance.fetchPosts(payload)
            actions.setPosts(data)
            actions.setFetching(false)
            
        } catch (error) {
            actions.setPosts()
        }
    })

})

export default store